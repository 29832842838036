import { atom } from 'recoil';

import { atomEffectLocalStorage } from 'helpers/atoms';
import type { ClientState } from 'types/recoil';
import { Atoms } from 'types/recoil';

export const atomForm = atom<ClientState[Atoms.Form]>({
  default: undefined,
  key: Atoms.Form,
});

export const atomModal = atom<ClientState[Atoms.Modal]>({
  default: undefined,
  key: Atoms.Modal,
});

export const atomPageState = atom<ClientState[Atoms.PageState]>({
  default: undefined,
  key: Atoms.PageState,
});

export const atomRecommendations = atom<ClientState[Atoms.Recommendations]>({
  default: {},
  effects: [atomEffectLocalStorage()],
  key: Atoms.Recommendations,
});

type AtomDialog = 'atomDialog';

export type Dialog =
  | {
      id: 'add-to-pipeline';
    }
  | {
      data: {
        name: string;
        teamsCount: number;
      };
      id: 'delete-trending-submission';
    }
  | {
      data: {
        name: string;
        teamsCount: number;
      };
      id: 'cancel-trending-submission';
    }
  | {
      id: 'introduction-feedback';
    }
  | {
      id: 'introduction-feedback-success';
    }
  | {
      id: 'introduction-request';
    }
  | {
      id: 'introduction-request-success';
    }
  | {
      id: 'people-filters';
    }
  | {
      data: {
        name: string;
        recommendationUuid: string;
        teamUuid: string;
      };
      id: 'recommendation-comments';
    }
  | {
      data: {
        recommendationUuid: string;
        submissionUuid: string;
      };
      id: 'recommendation-delete';
    }
  | {
      data: {
        name: string;
        teamUuid: string;
      };
      id: 'new-trending-recommendation-remove';
    }
  | {
      data: {
        recommendationUuid: string;
      };
      id: 'recommendation-edit';
    }
  | {
      id: 'submission-publish';
    }
  | {
      id: undefined;
    };

export type ExtractDataType<T, U> = T extends { data: infer D; id: U }
  ? D
  : undefined;

export const atomDialog = atom<Dialog>({
  default: {
    id: undefined,
  },
  key: 'atomDialog' satisfies AtomDialog,
});

export const atoms: Record<Atoms, any> = {
  atomForm,
  atomModal,
  atomPageState,
  atomRecommendations,
};
