import { ClerkProvider } from '@clerk/clerk-react';
import { broadcastQueryClient } from '@tanstack/query-broadcast-client-experimental';
import { QueryClientProvider } from '@tanstack/react-query';
import { theme as antTheme, ConfigProvider } from 'antd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import type { RecoilRootProps } from 'recoil';
import { RecoilRoot } from 'recoil';
import { ThemeUIProvider } from 'theme-ui';

import { ErrorBoundary } from 'components/ErrorBoundary';
import { LayoutFallback } from 'components/LayoutFallback';
import { Suspense } from 'components/Suspense';
import { queryClient } from 'helpers/query';
import { ErrorPage } from 'pages/Error';
import { theme } from 'types/theme';

const PUBLISHABLE_KEY = import.meta.env.VITE_APP_CLERK_PUBLISHABLE_KEY;

broadcastQueryClient({
  broadcastChannel: 'contender',
  queryClient,
});

const clerkLocalizations = {
  signIn: {
    alternativeMethods: {
      actionLink: 'Need help?',
    },
    start: {
      subtitle: 'Welcome back! Please sign in to continue',
      title: 'Sign in to Banff',
    },
  },
  userProfile: {
    profilePage: { title: 'Edit name' },
    start: {
      profileSection: { primaryButton: 'Edit name' },
    },
  },
};

export function AppProvider({
  children,
  initializeState,
}: Exclude<RecoilRootProps, { override: false }>) {
  return (
    <Suspense>
      <ClerkProvider
        appearance={{
          elements: {
            actionCard: 'shadow-elevation-200',
            alternativeMethodsBlockButton: 'clerk-button-primary',
            backLink: 'text-14/142% font-semibold',
            button:
              'data-[localization-key="signIn.alternativeMethods.getHelp.blockButton__emailSupport"]:clerk-button-primary',
            buttonArrowIcon: 'hidden',
            cardBox: 'shadow-elevation-300',
            checkbox:
              'checked:bg-blue-500 bg-gray-300 shadow-none hover:shadow-none active:shadow-none focus:shadow-none',
            footerActionLink: 'clerk-button-link',
            formButtonPrimary: 'clerk-button-primary',
            formButtonReset: 'clerk-button-secondary',
            formFieldAction: 'clerk-button-link',
            formFieldLabel: 'clerk-form-field-label',
            headerSubtitle: 'clerk-header-subtitle',
            headerTitle: 'text-20 font-default font-semibold',
            input: 'clerk-form-field-input',
            logoImage: 'w-10 h-10',
            menuButtonEllipsis:
              'border border-solid shadow-none outline-none transition duration-200 focus:shadow-none focus:outline-offset-0 cursor-pointer text-12/100% font-semibold justify-center rounded-[4px] border-transparent bg-transparent text-gray-600 focus:border-gray-600 focus:bg-transparent focus:outline focus:outline-gray-600 hover:bg-gray-100 active:border-gray-200 active:bg-gray-200',
            // Remove the opacity making navbar buttons look disabled
            navbarButton: 'opacity-100',
            profileSectionPrimaryButton: 'clerk-button-secondary',
            rootBox: 'font-default',
          },
          variables: {
            colorBackground: 'white',
            colorDanger: '#B52A2A',
            colorPrimary: '#000F93',
            colorText: 'black',
          },
        }}
        localization={clerkLocalizations}
        publishableKey={PUBLISHABLE_KEY}
      >
        {/* @ts-ignore Theme UI */}
        <ThemeUIProvider theme={theme}>
          <ConfigProvider
            theme={{
              algorithm: antTheme.defaultAlgorithm,
              token: {
                blue: theme.colors['blue-500'],
                colorBgLayout: '#fff',
                colorError: theme.colors['red-5'],
                colorInfo: theme.colors['ba-green-6'],
                colorInfoBg: theme.colors['ba-green-1'],
                colorInfoBorder: theme.colors['ba-green-3'],
                colorLink: theme.colors['ba-green-6'],
                colorLinkHover: theme.colors['ba-green-5'],
                colorPrimary: theme.colors['ba-green-6'],
                colorTextBase: theme.colors['gray-9'],
                fontFamily: theme.fonts.default,
                geekblue: theme.colors['geekblue-6'],
                gold: theme.colors['gold-6'],
                green: theme.colors['green-6'],
                lime: theme.colors['lime-6'],
                red: theme.colors['red-6'],
                screenLG: Number.parseInt(theme.mediaQueries.lg, 10),
                screenSM: Number.parseInt(theme.mediaQueries.sm, 10),
                screenXL: Number.parseInt(theme.mediaQueries.xl, 10),
                screenXS: Number.parseInt(theme.mediaQueries.xs, 10),
                screenXXL: Number.parseInt(theme.mediaQueries.xxl, 10),
                volcano: theme.colors['volcano-6'],
              },
            }}
          >
            <RecoilRoot initializeState={initializeState}>
              <QueryClientProvider client={queryClient}>
                <Suspense fallback={<LayoutFallback />}>
                  <ErrorBoundary
                    fallback={<ErrorPage variant="fullScreen" />}
                    name="AppProvider"
                  >
                    <DndProvider backend={HTML5Backend}>{children}</DndProvider>
                  </ErrorBoundary>
                </Suspense>
              </QueryClientProvider>
            </RecoilRoot>
          </ConfigProvider>
        </ThemeUIProvider>
      </ClerkProvider>
    </Suspense>
  );
}
