import type { CreateClientConfig } from '@hey-api/client-axios';
import queryString from 'query-string';

export const createClientConfig: CreateClientConfig = (config) => ({
  ...config,
  auth: async () => {
    // TODO: a less gross way to get a reference to the Clerk singleton outside
    // of React. (https://github.com/orgs/clerk/discussions/1751)
    // @ts-ignore
    const token = await window.Clerk?.session?.getToken();
    return token || undefined;
  },
  baseURL: import.meta.env.VITE_APP_BASE_URL || '',
  paramsSerializer: (params) => queryString.stringify(params),
});
