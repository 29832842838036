import { ClerkLoaded } from '@clerk/clerk-react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createRouter, RouterProvider } from '@tanstack/react-router';
import queryString from 'query-string';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { routeTree } from './routeTree.gen';

import { AppProvider } from 'components/AppProvider';
import * as serviceWorker from 'config/serviceWorker';
import { queryClient } from 'helpers/query';

// CSS reset, needed to hide some buttons for example
// learn more: https://github.com/ant-design/ant-design/issues/38732
import 'antd/dist/reset.css';

import 'config/i18n';
import 'config/logging';
import 'config/yup';

import 'config/styles.css';
import 'config/tailwind.css';

const container = document.getElementById('root');
const root = createRoot(container!);

const router = createRouter({
  context: {
    queryClient,
    role: undefined,
    user: undefined,
  },
  defaultPreload: 'intent',
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
  notFoundMode: 'fuzzy',
  parseSearch: (query) => queryString.parse(query),
  routeTree,
  stringifySearch: (search) => {
    if (!search) {
      return '';
    }

    const searchString = queryString.stringify(search, {
      arrayFormat: 'none',
    });

    return searchString ? `?${searchString}` : '';
  },
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

root.render(
  <StrictMode>
    <AppProvider>
      <ReactQueryDevtools buttonPosition="bottom-right" position="bottom" />
      <ClerkLoaded>
        <RouterProvider router={router} />
      </ClerkLoaded>
    </AppProvider>
  </StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
