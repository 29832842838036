import { useAuth } from '@clerk/clerk-react';
import * as Sentry from '@sentry/react';
import { useProfiler } from '@sentry/react';
import type { QueryClient } from '@tanstack/react-query';
import {
  createRootRouteWithContext,
  Outlet,
  useLocation,
  useRouter,
} from '@tanstack/react-router';
import { SpeedInsights } from '@vercel/speed-insights/react';
import cn from 'classnames';
import { DateTime } from 'luxon';
import posthog from 'posthog-js';
import { useEffect, useRef } from 'react';
import { useToaster } from 'react-hot-toast/headless';

import {
  getPeopleCurrentOptions,
  getTeamOptions,
} from 'client/@tanstack/react-query.gen';
import type { Person, PersonRole } from 'client/types.gen';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { useAxiosAuthInterceptor } from 'helpers/auth';
import { captureOkr, setUser } from 'helpers/logging';
import { getPersonRole } from 'helpers/person';
import { lazy } from 'helpers/react';
import { useYup } from 'helpers/yup';
import { ErrorPage } from 'pages/Error';

// Automatically refresh page for users who may be leaving tabs open forever.
// The threshold value should be set high enough to avoid affecting majority
// of regular users.
const windowForceRefreshIntervalInSeconds =
  import.meta.env.VITE_APP_WINDOW_FORCE_REFRESH_INTERVAL_IN_SECONDS || 0;

const TanStackRouterDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null
    : lazy(() =>
        import('@tanstack/router-devtools').then((response) => ({
          default: response.TanStackRouterDevtools,
        })),
      );

interface RouterContext {
  queryClient: QueryClient;
  role: PersonRole | undefined;
  user: Person | undefined;
}

export const Route = createRootRouteWithContext<RouterContext>()({
  beforeLoad: async ({ context, params }) => {
    const { queryClient } = context;

    // @ts-ignore
    const session = window.Clerk?.session;

    let user;

    try {
      const token = await session?.getToken();

      if (token) {
        user = await queryClient.ensureQueryData(getPeopleCurrentOptions());
      }
    } catch (error) {
      Sentry.captureException(error);
      await session?.end?.();
      queryClient.clear();
    }

    if (!user) {
      return { role: undefined, user };
    }

    const teamId = 'teamId' in params ? params.teamId : undefined;

    const currentTeam = teamId
      ? await queryClient.ensureQueryData(
          getTeamOptions({ path: { team_slug: teamId as string } }),
        )
      : undefined;

    const role = getPersonRole(
      user,
      currentTeam ? currentTeam.slug : undefined,
    );

    return { role, user };
  },
  component: RootComponent,
});

function RootComponent() {
  useProfiler('App');
  useYup();

  useAxiosAuthInterceptor();

  const auth = useAuth();
  const router = useRouter();
  const location = useLocation();
  const isInitialLoad = useRef(true);

  const { user } = Route.useRouteContext();

  const refSessionStart = useRef<DateTime>();

  const { handlers, toasts } = useToaster();
  const { calculateOffset, endPause, startPause, updateHeight } = handlers;

  useEffect(() => {
    if (windowForceRefreshIntervalInSeconds > 0) {
      refSessionStart.current = DateTime.fromISO(new Date().toISOString()).plus(
        { seconds: windowForceRefreshIntervalInSeconds },
      );
    }
  }, []);

  useEffect(() => {
    const threshold = refSessionStart.current;
    if (threshold && user?.id) {
      const now = DateTime.fromISO(new Date().toISOString());
      if (now > threshold) {
        captureOkr('refresh_due_to_stale_client', {
          timeout_seconds: windowForceRefreshIntervalInSeconds,
          user_id: user.id,
        });
        window.location.reload();
      }
    }
  }, [location.pathname, user?.id]);

  useEffect(() => {
    posthog.capture('$pageview');
  }, [location]);

  useEffect(() => {
    if (isInitialLoad.current) {
      isInitialLoad.current = false;
      return;
    }
    router.invalidate();
  }, [auth.isSignedIn, router]);

  useEffect(() => {
    setUser(user);
  }, [user]);

  return (
    <ErrorBoundary
      fallback={<ErrorPage variant="fullScreen" />}
      name="RootComponent"
    >
      <SpeedInsights
        debug={process.env.NODE_ENV !== 'production'}
        route={location.pathname}
        sampleRate={1.0}
      />
      <Outlet />
      <TanStackRouterDevtools />
      <div
        className="fixed bottom-2 right-2 z-20"
        onMouseEnter={startPause}
        onMouseLeave={endPause}
      >
        {toasts.map((x) => {
          const offset = calculateOffset(x, {
            gutter: 8,
            reverseOrder: false,
          });

          const ref = (el: HTMLDivElement | null) => {
            if (el && typeof x.height !== 'number') {
              const height = el.getBoundingClientRect().height;
              updateHeight(x.id, height);
            }
          };

          return (
            <div
              key={x.id}
              ref={ref}
              className={cn(
                'absolute bottom-0 right-0 z-20 min-w-max rounded-[30px] border border-solid border-gray-900 bg-gray-800 px-4 py-3 text-14/120% text-white transition-all duration-500 ease-out',
                {
                  'opacity-0': !x.visible,
                  'opacity-100': x.visible,
                },
              )}
              style={{
                transform: `translateY(-${offset}px)`,
              }}
              {...x.ariaProps}
            >
              {x.message as string}
            </div>
          );
        })}
      </div>
    </ErrorBoundary>
  );
}
