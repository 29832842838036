import {
  type ErrorBoundaryProps,
  ErrorBoundary as SentryErrorBoundary,
} from '@sentry/react';

const environment = import.meta.env.VITE_APP_SENTRY_ENV;
const isDev = environment === 'development';

/**
 * ErrorBoundary component to catch and handle errors using Sentry.
 *
 * @param {Object} props - The component props.
 * @param {string} props.name - The name of the error boundary location, e.g. "AppProvider", "Pipelines", "Peer Compare Dialog".
 * @param {React.ReactNode} props.children - The child components to render.
 * @returns {JSX.Element} The rendered component.
 */
export function ErrorBoundary({
  children,
  fallback,
  name,
}: {
  children: React.ReactNode;
  fallback?: ErrorBoundaryProps['fallback'];
  name: string;
}): JSX.Element {
  return (
    <SentryErrorBoundary
      beforeCapture={(scope) => {
        if (isDev) {
          // eslint-disable-next-line no-console
          console.log(`Error caught in ErrorBoundary at component: ${name}`);
        }

        scope.setTag('errorBoundaryLocation', name);
      }}
      fallback={fallback}
    >
      {children}
    </SentryErrorBoundary>
  );
}
