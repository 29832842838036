import { createFileRoute, redirect } from '@tanstack/react-router';
import { z } from 'zod';

import { CandidateTier } from 'client/types.gen';
import { zCandidateType, zFunction, zIndustry } from 'client/zod.gen';
import { zFiltersArray } from 'helpers/router';
import { dateRangeIntervalSchema } from 'helpers/zod';

const schema = z.object({
  compare: z.array(z.string()).optional(),
  compareDialog: z
    .union([
      z.literal('performance'),
      z.literal('profile'),
      z.literal('recommendations'),
    ])
    .optional()
    .catch(undefined),
  function_expertise: zFiltersArray(zFunction),
  industry_expertise: zFiltersArray(zIndustry),
  peer: z.string().optional(),
  period: dateRangeIntervalSchema,
  program: zFiltersArray(zCandidateType),
});

export const Route = createFileRoute(
  '/_private/_route/_internal/candidates/$candidateId/compare/',
)({
  beforeLoad: ({ context }) => {
    const { candidate } = context;

    if (candidate.tier !== CandidateTier.EXECUTIVE_CLIENT) {
      throw redirect({
        params: { candidateId: candidate.uuid },
        replace: true,
        to: '/candidates/$candidateId',
      });
    }
  },
  validateSearch: schema,
});
