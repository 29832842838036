import { createFileRoute, redirect } from '@tanstack/react-router';

import { getTeamTrendingOptions } from 'client/@tanstack/react-query.gen';

export const Route = createFileRoute(
  '/_private/_route/teams/$teamId/trending/',
)({
  beforeLoad: async ({ context }) => {
    const { currentTeam, queryClient } = context;
    const pTrendings = await queryClient.ensureQueryData(
      getTeamTrendingOptions({
        path: {
          team_uuid: currentTeam.slug,
        },
        query: {
          team_uuids: [currentTeam.uuid],
        },
      }),
    );

    const nextRecommendationId = pTrendings.recommendations[0]?.uuid;

    // go to first result if it exists
    if (nextRecommendationId) {
      throw redirect({
        params: {
          recommendationId: nextRecommendationId,
          teamId: currentTeam.slug,
        },
        replace: true,
        search: (search) => search,
        to: '/teams/$teamId/trending/$recommendationId',
      });
    }
  },
});
