import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

import { client } from 'client/client.gen';
import { useOnSuccessLogOut } from 'helpers/person';

export const useAxiosAuthInterceptor = () => {
  const queryClient = useQueryClient();

  const { onSuccessLogOut } = useOnSuccessLogOut();

  useEffect(() => {
    const authInterceptor = client.instance.interceptors.response.use(
      undefined,
      (error) => {
        try {
          const isUserLoadFail =
            error.response?.config.url === '/api/v1/people/current' &&
            error.response?.config.method === 'get' &&
            [401].includes(error.response?.status);

          if (isUserLoadFail) {
            onSuccessLogOut();
          }
        } catch (e) {
          Sentry.captureException(e);
        }

        return Promise.reject(error);
      },
    );

    return () => {
      client.instance.interceptors.response.eject(authInterceptor);
    };
  }, [onSuccessLogOut, queryClient]);
};
