import { useMutation } from '@tanstack/react-query';
import { getRouteApi, useParams } from '@tanstack/react-router';
import { useEffect } from 'react';

import {
  getTeamPipelineCandidatesOptions,
  getTeamTrendingOptions,
  postTeamCandidateViewsMutation,
  postTeamPipelineRecommendationViewsMutation,
  postTeamTrendingRecommendationViewsMutation,
} from 'client/@tanstack/react-query.gen';
import { updateQueriesData } from 'helpers/query';

const route = getRouteApi('/_private/_route/teams/$teamId');

export const useRecordCandidateView = () => {
  const params = useParams({
    strict: false,
  });
  const candidateId = 'candidateId' in params ? params.candidateId : undefined;
  const pipelineId = 'pipelineId' in params ? params.pipelineId : undefined;
  const recommendationId =
    'recommendationId' in params ? params.recommendationId : undefined;
  const teamId = 'teamId' in params ? params.teamId : undefined;

  const { currentTeam, queryClient } = route.useRouteContext();

  // People UI doesn't change depending on view status
  const { mutate: mutatePeople } = useMutation({
    ...postTeamCandidateViewsMutation(),
  });

  const { mutate: mutatePipeline } = useMutation({
    ...postTeamPipelineRecommendationViewsMutation(),
    onMutate: () => {
      updateQueriesData({
        ...getTeamPipelineCandidatesOptions({
          path: {
            pipeline_uuid: pipelineId!,
            team_uuid: teamId!,
          },
        }),
        queryClient,
        updater: (state) => ({
          ...state,
          recommendations: state.recommendations.map((recommendation) => {
            if (recommendation.uuid !== recommendationId) {
              return recommendation;
            }

            return {
              ...recommendation,
              is_viewed: true,
            };
          }),
        }),
      });
    },
  });

  const { mutate: mutateTrending } = useMutation({
    ...postTeamTrendingRecommendationViewsMutation(),
    onMutate: () => {
      updateQueriesData({
        ...getTeamTrendingOptions({
          path: {
            team_uuid: teamId!,
          },
          query: {
            team_uuids: [currentTeam.uuid],
          },
        }),
        queryClient,
        updater: (state) => ({
          ...state,
          recommendations: state.recommendations.map((recommendation) => {
            if (recommendation.uuid !== recommendationId) {
              return recommendation;
            }

            return {
              ...recommendation,
              is_viewed: true,
            };
          }),
        }),
      });
    },
  });

  useEffect(() => {
    if (!teamId) {
      return;
    }

    if (candidateId) {
      mutatePeople({
        body: {
          has_viewed_activity: false,
        },
        path: {
          candidate_uuid: candidateId,
          team_uuid: teamId,
        },
      });
      return;
    }

    if (!recommendationId) {
      return;
    }

    if (pipelineId) {
      mutatePipeline({
        body: {
          has_viewed_activity: false,
        },
        path: {
          pipeline_uuid: pipelineId,
          recommendation_uuid: recommendationId,
          team_uuid: teamId,
        },
      });
      return;
    }

    mutateTrending({
      body: {
        has_viewed_activity: false,
      },
      path: {
        recommendation_uuid: recommendationId,
        team_uuid: teamId,
      },
    });
  }, [
    mutatePeople,
    mutatePipeline,
    mutateTrending,
    candidateId,
    pipelineId,
    recommendationId,
    teamId,
  ]);
};
