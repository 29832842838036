import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_private/_route')({
  beforeLoad: async () => {
    // @ts-ignore
    const metadata = window.Clerk?.user?.publicMetadata || {};

    if (
      'onboarding_complete' in metadata &&
      metadata.onboarding_complete === false
    ) {
      throw redirect({ replace: true, to: '/signup' });
    }
  },
});
