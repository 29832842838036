import cn from 'classnames';
import { useTranslation } from 'react-i18next';

export function ErrorPage({ variant }: { variant?: 'fullScreen' }) {
  const { t } = useTranslation();

  return (
    <div
      className={cn('flex items-center justify-center bg-gray-100 p-10', {
        'h-full': variant !== 'fullScreen',
        'min-h-screen': variant === 'fullScreen',
      })}
    >
      <div className="flex max-w-[460px] flex-col gap-4 rounded-[12px] bg-white px-10 pb-10 pt-8 shadow-elevation-100">
        <span className="text-heading-400 block text-gray-700">
          {t('page.error.noticeCard.data.title')}
        </span>
        <span className="text-body-200 block text-gray-600">
          {t('page.error.noticeCard.data.text')}
        </span>
      </div>
    </div>
  );
}
