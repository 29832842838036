import { createFileRoute, redirect } from '@tanstack/react-router';
import posthog from 'posthog-js';
import { z } from 'zod';

const schema = z.object({
  task: z.string().optional(),
  tasks: z
    .union([
      z.literal('completed'),
      z.literal('overdue'),
      z.literal('upcoming'),
    ])
    .optional()
    .catch(undefined),
});

export const Route = createFileRoute('/_private/_route/_internal/home/')({
  beforeLoad: ({ search }) => {
    if (!posthog.isFeatureEnabled('project-admin-hub')) {
      throw redirect({
        replace: true,
        to: '/',
      });
    }

    if (!search.tasks) {
      throw redirect({
        replace: true,
        search: {
          tasks: 'upcoming',
        },
        to: '.',
      });
    }
  },
  validateSearch: schema,
});
